import { Grid, List, ListItem, Typography, useMediaQuery, useTheme } from "@mui/material";
import StyledDivider from "./Divider";

export default function Workforce () {
    
    const theme = useTheme();
    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));


    const workforceItems = [
        "Engineers and Surveyors",
        "CADD Engineers",
        "Rtd. Environmental officers from KSPCB",
        "Rtd. Fire officers from State Fire and Emergency",
        "Rtd. Tahsildhar from Govt.of Tamil Nadu",
        "Administrative and Facility",
        "Assistants and Helpers",
    ]
    return (
        <Grid
            sx={{
                height: {xs: "auto", lg: "auto"},
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="stretch"
            flexDirection="column"
        >
            <Grid
                width="100%"
                display="flex"
                alignItems="center"
                padding={2}
                paddingLeft={4}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    height: "10%"
                }}
            >
                <Typography
                    variant="h3"
                    fontFamily="HeaderFont"
                    letterSpacing={3}
                    fontSize={{xs: "3vh", lg: "4.5vh"}}
                >
                    Workforce
                </Typography>
            </Grid>
            <Grid
                flexGrow={1}
                padding={{xs : "10% 5% 10% 5%", lg: 5}}
                display="flex"
                alignItems={{xs: "flex-start", lg: "flex-start"}}
                flexDirection="column"
                rowGap={{xs: 3, lg: 1}}
            >
                <Typography
                    fontFamily="BodyText"
                    width={{xs: "80%", lg: "70%"}}
                    fontSize={{xs: "2.8vh", lg: "3.5vh"}}
                    height="fit-content"
                    sx={{
                        textAlign: {xs: "left", lg: "Left"},
                    }}
                >
                    We are a team of well qualified & professionally managed firm serving the civil engineering and other industrial sectors.
                </Typography>
                
                <StyledDivider 
                    sx={{
                        width:"60%",
                        height: {xs: "1vh", lg: "1.5vh"},
                        alignSelf: "flex-start",
                        backgroundColor: theme.palette.alter.main,
                        marginLeft: 0
                    }}    
                />
                <Grid
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"

                    flexGrow={1}
                    width="100%"
                    paddingTop={1}

                >
                    <Grid
                        order={{xs: 2, lg: 0}}
                        width={{xs: "100%", lg: "55%"}}
                    >
                        <Typography
                            fontSize={{xs: "2.8vh", lg: "3.4vh"}}
                            paddingLeft={1}
                            width="100%"
                            fontWeight={600}
                        >
                            Our support staff includes
                        </Typography>
                        <List
                            sx={{
                                paddingLeft: {xs: 1, lg: 6},
                                width: {xs: "100%", lg: "100%"}
                            }}
                        >
                            {workforceItems.map((item, index) => (
                                <ListItem
                                    key={index}
                                >
                                    <Grid
                                        fontSize={{xs: "2.8vh", lg: "4vh"}}
                                    >
                                        &#8226;
                                    </Grid>
                                    <Typography
                                        fontSize={{xs: "2.2vh", lg: "3vh"}}
                                        marginLeft={1}
                                        sx={{
                                            textDecoration: 'none', 
                                            background: `linear-gradient(${theme.palette.alter.main}, ${theme.palette.alter.main}) bottom / 0 2px no-repeat`,
                                            transition: 'background-size 0.5s', 
                                            backgroundPosition: "left bottom",
                                            '&:hover': {
                                                backgroundSize: '100% 2px',
                                            },
                                        }}
                                    >
                                    
                                    {item}
                                    
                                    </Typography>
                                </ListItem>
                            ))}
                        </List>
                    </Grid>
                    
                    <img
                        src={require('../assets/images/workforce.jpg')}
                        alt="About"
                        style={{
                            objectFit: "contain",
                            objectPosition: "center center",
                            padding: isLargeScreen ? theme.spacing(2) : theme.spacing(8),
                            width: isLargeScreen ? "40%" : "100%",
                            height: isLargeScreen ? "50vh" :"40vh"
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}
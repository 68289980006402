import { Divider, Grid, Typography, styled, useTheme } from "@mui/material";
import { forwardRef } from "react";
import Flickity from "react-flickity-component";

import '../stylesheets/flickity.css';

const About = forwardRef((props, ref) => {
    const StyledGrid = styled(Grid)(({ theme }) => ({
        display: 'grid',
        gridAutoColumns: '1fr',
        gridGap: '1em 1em',
        placeItems: 'stretch stretch',
        placeContent: 'stretch stretch',
        gridAutoFlow: 'row',
        maxWidth: '100%',
        padding: theme.spacing(4),
    
          
        [theme.breakpoints.up('xs')]: {
            // Styles for xs breakpoint
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridAutoRows: true,
        },
      
        [theme.breakpoints.up('lg')]: {
            // Styles for lg breakpoint
            gridTemplateColumns: 'repeat(24, 1fr)',
            gridTemplateRows: 'repeat(17, 1fr)'
        },
    }));
    
    const theme = useTheme();

    const office_pics = [
        require("../assets/images/office/office_entrance.jpeg"),
        require("../assets/images/office/office_portrait_1.jpeg"),
        require("../assets/images/office/office_portrait_2.jpeg"),
        require("../assets/images/office/office_portrait_3.jpeg"),
    ]

    const flickityOptions = {
        wrapAround: true,
        autoPlay: 4000,
        prevNextButtons: false,
        draggable: true,

    }
    return(
        <Grid
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            justifyContent="flex-start"
            sx={{
                    height: {xs: "auto", lg: "auto"},
                    minHeight: { xs: "auto", lg: "100%"},
                    maxHeight: {xs: "500%", lg: "auto"},
                    width: "100%",
                }}
            id="About"
            ref={ref}
        >
        {/*
            <Grid
                width="100%"
                display="flex"
                alignItems="center"
                padding={4}
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    height: {xs: "5%", lg: "8vh"}
                }}
            >
                
                <Typography
                    variant="h3"
                    fontFamily="HeaderFont"
                    letterSpacing={3}
                    fontSize={{xs: "3vh", lg: "4.5vh"}}
                >
                    About
                </Typography>
            </Grid> */}
            <StyledGrid
                sx={{
                    height: {xs: "auto", lg: "90%"},
                    maxHeight: "95%",
                    width: "100%",
                }}
                flexGrow={1}
            >
                
                <Grid
                    item
                    sx={{
                    gridArea: {
                        xs: '1 / 1 / auto / 5',
                        lg: '2 / 2 / 5 / 15',
                    },
                    }}
                    className="title"
                    padding={4}
                    paddingTop={2}
                    paddingBottom={2}
                    display="flex"
                    direction="column"
                    justifyContent="flex-start"
                    rowGap={{xs: 4, lg: 2}}
                    height="auto"
                >
                    <Typography
                        fontSize={{xs: "3.5vh", lg: "4vh"}}
                        display= 'flex'
                        alignItems= 'center'
                        padding={1}
                        color={theme.palette.text.primary}
                        fontWeight={600}
                        fontFamily="HeaderFont"
                    >
                        We are an established company providing multiple services in the Engineering Sector.
                    </Typography>
                    <Divider
                        sx={{
                            height: "2vh",
                            width: "60%",
                            backgroundColor: theme.palette.alter.main,
                            border: 0,
                            marginLeft: 1
                        }}
                    />
                    {/*<Typography
                        display= 'flex'
                        alignItems= 'center'
                        fontSize={{xs: "3vh", lg: "3.5vh"}}
                        padding={1}
                        fontWeight={550}
                        fontFamily="HeaderFont"
                        color={theme.palette.text.primary}
                    >
                        Equipped with highly advanced and up to date industrial Equipments.
                    </Typography>*/}
                </Grid>
                <Grid
                    item
                    sx={{
                        gridArea: {
                            xs: 'auto / 1 / auto / 5',
                            lg: '3 / 15 / 17 / 26',
                        },
                    }}
                    className="image"
                    padding={{xs: 5, lg: 0}}
                >
                    <Flickity
                        options={flickityOptions}
                    >
                        {
                            office_pics.map((src, index) => (
                                <Grid
                                    key={index}

                                    display="flex"
                                    justifyContent="center"
                                    padding={1}
                                >
                                
                                    <img
                                        src={src}
                                        alt="gallery"
                                        style={{
                                            objectFit: "contain",
                                            width: "100%",
                                        }}
                                    />
                                </Grid>
                            ))
                        }
                    </Flickity>
                    {/*<img
                        src={require('../assets/images/office/office_entrance.jpeg')}
                        alt="About"
                        style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                            width: "100%",
                            height: isLargeScreen ? "100%" :  "80vh",
                            padding: isLargeScreen? "5% 15% 15% 0%" : "0%",
                        }}
                    />*/}
                </Grid>

                <Grid
                    item
                    sx={{
                        gridArea: {
                            xs: 'auto / 1 / span 3 / 5',
                            lg: '4 / 2 / 15 / 15',
                        },
                        padding: 4,
                        paddingTop: 2,
                        paddingBottom: 0
                    }}
                    className="description"
                    
                >
                    <Typography
                        fontSize={{xs: "2.5vh", lg: "2.8vh"}}
                        padding={1}
                        variant="body2"
                        sx={{//whiteSpace: "pre-wrap"
                        }}
                        paddingRight={{xs: 1, lg: 5}}
                        align="justify"
                        fontFamily="BodyText"
                    >
                        <b>Acre 2 Hectares (formerly known as Associate Surveys)</b> was established in the year August 2002.
                        Acre 2 Hectares is a <b>Mapping, Planning and Liaison firm</b>, which provides services in the field of Civil Engineering viz., 
                        Mapping, Data Conversion, Processing and Digitizing jobs in the Residential and Industrial Projects 
                        from conception to completion. During the past 21 years, Acre 2 Hectares has executed major projects 
                        to both the private and public sector firms.
                        <br />
                        <br />
                        
                        {'      '}
                        <b>
                            From past 6 years Acre 2 Hectares is providing services to the 
                            major industries in the field of Pre and Post Establishment approvals 
                            from various Government Authorities.
                        </b>
                        <br />
                        <br />
                        
                        {'      '}
                        Our expertise include obtaining plan sanctions from KIADB, DTCP, UDA, CMC, DISH, 
                        Consent to Establish and Consent to Operate from State Pollution Control Boards, 
                        preparation of Onsite emergency Plans for Hazardous Industries and Obtaining License 
                        from Director of Industrial Safety & Hazardous Department, obtaining State Level Site Appraisal 
                        Committee Approval from the State Government, and obtaining No Objection Certificates from District 
                        Magistrate for all kinds of retail outlets like <b>CNG, CGS and LCNG stations.</b>
                        <br />
                        <br />
                        
                        {'      '}
                        Acre 2 Hectares team is committed in creating innovative solutions in the development of future
                        infrastructure. We strive to provide each client with the best engineering solutions available in an
                        economically feasible and environmentally sound manner.
                    </Typography>
                </Grid>
                
                <Achievement />

            </StyledGrid>
        </Grid>
    )
})

function Achievement () {
    const theme = useTheme();
    return(

        <Grid
            item
            sx={{
                gridArea: {
                    xs: 'auto / 1 / span 5 / 5',
                    lg: 'span 3 / 5 / 18 / 21',
                },
            }}
            className="achievement"
            padding={{xs: 12, lg: 6}}
            display="flex"
            flexWrap="wrap"
            zIndex={1}
        >
            <AchievementPaper
                color={theme.palette.alter.main}
                count="150+"
            >
                Projects
            </AchievementPaper>

            <AchievementPaper 
                color={theme.palette.alter.main}
                count="21"
            >
                Years of Experience
            </AchievementPaper>
            
            <AchievementPaper 
                color={theme.palette.alter.main}
                count="200"
            >
                Happy Clients
            </AchievementPaper>
            
            <AchievementPaper 
                color={theme.palette.alter.light}
                count="18"
            >
                Quality Certificates
            </AchievementPaper>
        </Grid>
    )
}

function AchievementPaper(props) {
    return (
        
        <Grid
            flexGrow={1}
            xs={12} 
            lg={3}
            
            sx={{
                backgroundColor: props.color,
            }}
            display="flex"
            flexDirection="column"
            alignItems="stretch"
            alignContent="stretch"
            maxHeight="100%"
        >

            <Grid
                flexGrow={1}
                height="50%"
                display="flex"
                alignItems="stretch"
                flexDirection="column"
                justifyContent="center"
            >
                <Typography
                    color={"white"}
                    fontSize={{xs: "6vh", lg:"4.5vh"}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    fontWeight={600}
                >
                    {props.count}
                </Typography>
            
                <Typography
                    color={"white"}
                    fontSize={{xs: "3vh", lg:"2.5vh"}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                >
                    {props.children}
                </Typography>
            </Grid>

        </Grid>
    )
}

export default About;
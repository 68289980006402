import { useTheme } from "@mui/material";
import { Divider, Grid, Typography, styled } from "@mui/material";
import { forwardRef } from "react";
import StyledDivider from "./Divider";
import CloseIcon from '@mui/icons-material/Close';



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    width: '100%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    height: "90%",
    maxHeight: "90%",
};
  

const Liaison = forwardRef((props, ref) => {
    const StyledGrid = styled(Grid)(({ theme }) => ({
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoRows: '1fr',
        placeItems: 'stretch stretch',
        placeContent: 'stretch stretch',
        gridAutoFlow: 'row',
        maxWidth: '100%',
        maxHeight: "100%",
          
        [theme.breakpoints.up('xs')]: {
            // Styles for xs breakpoint
            gridAutoColumns: true,
            gridTemplateRows: 'repeat(16, 1fr)',    
            maxHeight: '200%',
        },
      
        [theme.breakpoints.up('lg')]: {
            // Styles for lg breakpoint
            gridTemplateColumns: 'repeat(12, 1fr)',
            gridTemplateRows: 'repeat(22, 1fr)',
            maxHeight: 'auto',
        },
    }));

    const GalleryGrid = styled(Grid)(({ theme }) => ({
        width: '100%',
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        rowGap: theme.spacing(16),
        columnGap: theme.spacing(8),

        marginTop: theme.spacing(10)
    
    }));

    const seals = [
        require("../assets/images/pcb.png"),
        require("../assets/images/bocw.png"),
        require("../assets/images/fire.png"),
        require("../assets/images/kpcl.png"),
    ]

    const LiaisonGallery = [
        require("../assets/images/liaison_gallery/liaison_gallery_1.jpeg"),
        require("../assets/images/liaison_gallery/liaison_gallery_2.jpeg"),
        require("../assets/images/liaison_gallery/liaison_gallery_3.jpeg"),
        require("../assets/images/liaison_gallery/liaison_gallery_4.jpeg"),
        require("../assets/images/liaison_gallery/liaison_gallery_5.jpeg"),
    ]

    const theme = useTheme();

    return(
        <Grid
            sx={style}
            overflow="scroll"
        >
            <CloseIcon
                sx={{
                    position: 'absolute',
                    right: 0,
                    transform: {
                        xs: 'translate(-25%, 0%)',
                        lg:'translate(-50%, -50%)'
                    },
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 1,
                    height: '4vh',
                    width: '4vh'
                }}
                onClick={props.handleClose}
            />
        <StyledGrid
            display="flex"
            flexDirection="column"
        >
            
            <Grid
                sx={{
                    gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "1 / 7 / 6 / 13"
                    }
                }}
                className="image"
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <img
                    src={props.modalImage1}
                    alt="Liaison"
                    style={{
                        objectFit: "cover",
                        objectPosition: "center center",
                        width: "80%",
                        height: "80%"
                    }}
                />
            </Grid>
            
            <Grid
                sx={{
                    gridArea: {
                        xs: "1 / 1 / auto / 5",
                        lg: "1 / 1 / auto / 7"
                    }
                }}
                className="Description"
                display="flex"
                direction="column"
                justifyContent="center"
                rowGap={{xs: 3, lg: 1}}
                padding={4}
            >
                <Typography
                    fontSize={{xs: "4vh", lg: "4vh"}}
                    display= 'flex'
                    alignItems= 'center'
                    padding={1}
                    color={theme.palette.alter.dark}
                    fontWeight={550}
                    fontFamily="HeaderFont"
                >
                    {props.title}
                </Typography>
                <Divider
                    sx={{
                        height: "1.5vh",
                        width: "60%",
                        backgroundColor: theme.palette.alter.main,
                        border: 0,
                        marginLeft: 1
                    }}
                />
                <Typography
                    display= 'flex'
                    alignItems= 'center'
                    fontSize={{xs: "2.2vh", lg: "2.8vh"}}
                    padding={1}
                    sx={{
                        fontWeight: 400
                    }}
                    align="justify"
                    fontFamily="BodyText"
                >
                    {props.modalDescription}
                </Typography>
                
            </Grid>
            <Grid
                sx={{
                    gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "auto / 1 / auto / 13"
                        
                    }
                }}
                display="flex"
                alignItems="flex-start"
                flexWrap="wrap"
                
                className="Highlights"
            >
                {
                    props.highlights.map((obj, index) => (
                        <Highlights
                            obj={obj}
                        />
                    ))
                }
                <StyledDivider width="100%" sx={{display: {lg: "none"}}}/>
            </Grid>
            
            <Grid
                sx={{
                    gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "auto / 3 / auto / 11"
                    }
                }}
                display="flex"
                flexWrap="wrap"
                flexDirection="row"
                className="Seal"
                justifyContent="center"
            >
                {
                    seals.map((src, index) => (
                        <Seals src={src}/>
                    ))
                }
            </Grid>
                
            <GalleryGrid
                sx={{
                    gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "auto / 1 / auto / 13"
                        
                    },
                }}

                className="LiaisonGallery"
            >
                {
                    LiaisonGallery.map((src, index) => (
                        <Grid
                            key={index}

                            display="flex"
                            justifyContent="center"

                            sx={{
                                height: "auto",
                                width: {
                                    xs: "100%",
                                    lg: "28%"
                                }
                            }}
                        >
                            <img
                                src={src}
                                alt="gallery"
                                style={{
                                    objectFit: "contain",
                                    height: "40vh",
                                    maxWidth: "100%"
                                }}
                            />
                        </Grid>
                    ))
                }
            </GalleryGrid>
            
        </StyledGrid>
        </Grid>
    )
})

function Seals (props) {
    return(
        <Grid
            display="flex"
            flexDirection="row"
            justifyContent="space-around"
            flexWrap='wrap'
            maxWidth="25%"
        >
            <img
                src={props.src}
                alt="Liaison"
                style={{
                    objectFit: "contain",
                    objectPosition: "center center",
                    width: "100%",
                    height: "100%",
                    padding: "15%"
                }}
            />
        </Grid>
    )
    
}

function Highlights({obj}) {
    return (
        <Grid
            xs={6}
            lg={3}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="stretch"
            padding={2}
        >
            <Grid
                maxHeight={{xs: "35%", lg: "40%"}}
                display="flex"
                justifyContent="center"    
            >
                <img
                    src={obj.src}
                    alt="Liaison"
                    style={{
                        objectFit: "contain",
                        objectPosition: "center center",
                        width: "50%",
                        height: "100%",
                        padding: "10%"
                    }}
                />
            </Grid>
            <Grid height="auto" maxHeight="20%">
                <Typography
                    variant="h5"
                    fontSize={{xs: "2.2vh", lg:"2.8vh"}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    padding={1}
                    height="100%"
                    wordWrap="break-word"
                    fontWeight={600}
                    fontFamily="HeaderFont"
                >
                    {obj.title}
                </Typography>
            </Grid>
            <Grid flexGrow={1} maxHeight={{xs: "45%", lg: "40%"}}>
                <Typography
                    variant="subtitle1"
                    fontSize={{xs: "2vh", lg: "2.5vh"}}
                    maxHeight="100%"
                    wordWrap="break-word"
                    lineHeight={{xs: "2.2vh", lg: "2.5vh"}}
                    padding={1}
                    fontFamily="BodyText"
                    align="justify"
                >
                    {obj.description}
                </Typography>
            </Grid>
        </Grid>
    )
}

export default Liaison;
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { forwardRef } from "react";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from "react-slick";
import { useMeasure } from "react-use";

const Clients = forwardRef((props, ref) => {

    const theme = useTheme();
    
    const settings = {
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        rows: 1,
    };
    const smallScreenSettings = {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: false,
        dots: false,
        pauseOnHover: true,
        rows: 2,
        adaptiveHeight: true
    };

    const slideImages = [
        require("../assets/images/clients/Adani Total Gas Limited.png"),
        require("../assets/images/clients/prestige.png"),
        require("../assets/images/clients/Bombay_Burmah_Trading_Corporation_logo.svg.png"),
        require("../assets/images/clients/biocon.png"),
        require("../assets/images/clients/corporate_crop.png"),
        require("../assets/images/clients/country-club.png"),
        require("../assets/images/clients/AG&P.png"),
        require("../assets/images/clients/irm_energy_logo.jpg"),
        require("../assets/images/clients/MSIL.png"),
        require("../assets/images/clients/Mylan.png"),
        require("../assets/images/clients/tirumala_tirupathi_devasthanam.jpg"),
        require("../assets/images/clients/Torrent.png"),
        require("../assets/images/clients/NDDB.png"),
    ]

    const [lgRef, lgState] = useMeasure();
    
    return (
    <Grid
        display="flex"
        flexDirection="column"
        alignItems="stretch"
        justifyContent="flex-start"
        sx={{
                maxHeight: "100%",
                height: {xs: "70%", lg: "80%"},
                width: "100%",
            }}
        id="About"
        ref={ref}
    >
        <Grid
            width="100%"
            display="flex"
            alignItems="center"
            padding={4}
            sx={{
                backgroundColor: theme.palette.secondary.main,
                height: "10%"
            }}
        >
            <Typography
                variant="h3"
                fontFamily="HeaderFont"
                letterSpacing={3}
                fontSize={{xs: "3vh", lg: "4.5vh"}}
            >
                Our Esteemed Clients
            </Typography>
        </Grid>

        <Grid
            display="flex"
            flexDirection="column"
            justifyContent={{xs: "flex-start", lg: "center"}}
            alignItems="center"
            height={{xs: "100%", lg: "100%"}}
            className="cont"
        >
            
            {/* large screen slider */}
            <Grid
                minHeight="50%"
                maxHeight="70%"
                width="80%"
                ref={lgRef}
                display={{xs: "none", lg: "block"}}
                justifyContent="center"
                className="lg"
            >
                <Slider {...settings}
                    style={{
                        maxHeight: "100%",
                        maxWidth: "100%",
                    }}
                >
                    {
                        slideImages.map((slideimage, index) => (
                            <Box
                                height="50%"
                                padding={5}
                                display="flex"
                                justifyContent="center"
                            >
                                <img
                                    src={slideimage}
                                    alt="img"
                                    style={{
                                        objectFit: "contain",
                                        objectPosition: "center center",
                                        padding: 5,
                                        height: lgState.height * 0.7,
                                        maxWidth: "100%"
                                    }}
                                />
                            </Box>
                        ))
                    }

                </Slider>
            </Grid>
                    
            {/* small screen slider */}
            <Grid
                minHeight="150%"
                maxHeight="70%"
                width="100%"
                display={{lg: "none", xs: "block"}}
                className="xs"
            >
                <Slider {...smallScreenSettings}
                    style={{
                        maxHeight: "70%",
                        maxWidth: "100%",
                    }}
                >
                    {
                        slideImages.map((slideimage, index) => (
                            <Box
                                maxHeight="100%"
                                padding={5}
                                display="flex"
                                justifyContent="center"
                            >
                                <img
                                    src={slideimage}
                                    alt="img"
                                    style={{
                                        objectFit: "contain",
                                        objectPosition: "center center",
                                        padding: 50,
                                        height: "20vh",
                                        maxWidth: "90vw",
                                        display: "block", margin: "0 auto"
                                    }}
                                />
                            </Box>
                        ))
                    }

                </Slider>
            </Grid>
        </Grid>
    </Grid>
    )
})

export default Clients;
import { Grid } from "@mui/material";

import { useMeasure } from "react-use";
import { StackedCarousel, ResponsiveContainer } from "react-stacked-center-carousel";
import { memo, useEffect, useRef } from "react";
import Carousel from "react-material-ui-carousel";

import "../stylesheets/slide.css";


export default function Slideshow () {

    const images = [
        {
            image: require("../assets/images/survey2.jpg")
        },
        {
            image: require("../assets/images/hero5.png")
        },
        {
            image: require("../assets/images/hero6.png")
        },
        {
            image: require("../assets/images/slideshow-2.jpeg")
        },
        {
            image: require("../assets/images/Pic 4 (1).jpeg")
        },
        {
            image: require("../assets/images/Pic 4 (2).jpeg")
        },
        
    ]

    const [gRef, state] = useMeasure();
    
    const ref = useRef();

    useEffect(() => {
        setInterval(() => {
            ref.current?.goNext();
        }, 5 * 1000);
    }, [])
    return (
        <Grid
            container
            sx={{
                flex: 1,
                width: "100%",
                display: "block",
                minHeight: "30%"
            }}
        >
        
            <Grid
                item
                ref={gRef}
                display={{xs: "block", lg: "none"}}
                width="100%"
            >
                <Carousel
                    animation="slide"
                    duration={500}
                    indicators={false}
                >
                    {images.map((src, index) => (
                        <img
                            src={src.image}
                            alt={`Carousel ${index}`}
                            style={{
                                height: 9 / 16 * state.width - 20,
                                width: state.width,
                                objectFit: "cover"
                            }}
                        />
                    ))}
                </Carousel>
            </Grid>
            <Grid
                display={{xs: "none", lg: "flex"}}
            >
                <ResponsiveContainer
                    carouselRef={ref}
                    render={(width, carouselRef) => {
                        return(
                            <StackedCarousel
                                ref={carouselRef}
                                slideComponent={Slide}
                                slideWidth={1024}
                                carouselWidth={width}
                                data={images}
                                maxVisibleSlide={5}
                                disableSwipe
                                customScales={[1, 0.85, 0.7, 0.55]}
                                transitionTime={450}
                            />
                        )
                    }}
                />
            </Grid>
        </Grid>
    )

}

const Slide = memo(function (StackedCarouselSlideProps) {
    const {
      data,
      dataIndex,
      isCenterSlide,
      swipeTo,
      slideIndex
    } = StackedCarouselSlideProps;
  
    const coverImage = data[dataIndex].image;
    
    return (
      <div className="card-card" draggable={false}>
        <div className={`cover fill ${isCenterSlide ? "off" : "on"}`}>
          <div
            className="card-overlay fill"
            onClick={() => {
              if (!isCenterSlide) swipeTo(slideIndex);
            }}
          />
        </div>
        <div className="detail fill">
          <div className="discription">
            <img
              style={{ width: "100%" }}
              alt="j"
              className="cover-image"
              src={coverImage}
            />
          </div>
        </div>
      </div>
    )
})

import { useTheme } from "@emotion/react";
import { Box, Grid, Modal, Paper, Typography } from "@mui/material";
import "../stylesheets/fonts.css";
import { useMeasure } from "react-use";
import React, { useState } from "react";
import ServicesModal from "./ServicesModal";
import Liaison from "./Liaison";
import images from "./serviceContent";

const Services = React.forwardRef((props, ref) => {

    const theme = useTheme();

       
    
    const [hovered, setHovered] = useState(0);

    return (
        <Grid
            sx={{
                height: "100%", //change to fit smaller screen
                flexDirection: {
                    xs: "column", lg: "row"
                }
            }}
            display="flex"
            justifyContent="flex-start"
            alignItems="stretch"
            ref={ref}
            id="Services"
        >
            {/*Title small screen*/}
            <Grid
                sx={{
                    backgroundColor: theme.palette.secondary.main,
                    height: "10%",
                    display: {
                        xs: "flex", lg: "none"
                    },
                    order: {
                        xs: -1, lg: 0
                    }
                }}
                alignItems="center"
                padding={4}
                flexShrink={1}
            >
                <Typography
                    variant="h3"
                    fontFamily="HeaderFont"
                    letterSpacing={3}
                    fontSize="3vh"
                >
                    Services
                </Typography>
            </Grid>
            
            <Grid
                xs={12}
                lg={8}
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignContent="stretch"
                alignItems="stretch"
                wrap="wrap"
                sx={{
                    height: {
                        xs: "60%", lg: "100%"
                    },
                }}
            >
                {/*Title Large Screen*/}
                <Grid
                    sx={{
                        backgroundColor: theme.palette.secondary.main,
                        height: "10%",
                        display: {
                            xs: "none", lg: "flex"
                        }
                    }}
                    flexShrink={1}
                    alignItems="center"
                    padding={4}
                >
                    <Typography
                        variant="h4"
                        fontFamily="HeaderFont"
                        letterSpacing={3}
                        fontSize="4.5vh"
                    >
                        Services
                    </Typography>
                </Grid>
                <ImagesHolder images={images} hovered={hovered} setHovered={setHovered}/>
            </Grid>
            <ImageHover hovered={hovered} images={images}/>
        </Grid>
    )
})

function ImageHover(props) {

    const theme = useTheme();

    const [ref, state] = useMeasure();

    return (
        <Grid
            sx={{
                height: "auto",
                maxHeight: "100%",
                flexGrow: 1,
                backgroundColor: theme.palette.primary.main,
                display: {xs: "none", lg: "flex"}  
            }}
            flexDirection={{xs: "row", lg: "column"}}
            alignItems="stretch"
            justifyContent="center"
            padding={4}
        >
            <Paper
                sx={{
                    width:"100%",
                    height: "auto",
                    display: {xs: "flex", lg: "block"}
                }}
                padding={2}
                backgroundColor={theme.palette.primary.main}
                elevation={1}
            >
        
            <Grid
                sx={{
                    height: {xs: "100%", lg: "auto"},
                    width: {xs: "50%", lg: "100%"}
                }}
                className="imgContainer"
                display="flex"
                flexDirection="column"
                alignItems="stretch"
                alignContent="stretch"
            >
                
                <Box
                    sx={{
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <Typography
                        variant="h2"
                        fontSize="3.2vh"
                        align="center"
                        paddingTop={2}
                        fontFamily="HeaderFont"
                    >
                        {props.images[props.hovered].title}
                    </Typography>
                </Box>
                
                <Box
                    height="30vh"
                    ref={ref}
                >
                    <img
                        src={props.images[props.hovered].src}
                        alt="img"
                        style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                            height: state.height,
                            width: state.width,
                            padding: 20,
                        }}
                    />
                </Box>
            </Grid>      

            <Grid
                sx={{
                    height: {xs: "100%", lg: "60%"},
                    width: { xs: "50%", lg: state.width},
                }}
                display="flex"
                flexWrap="wrap"
                padding={2}
                className="Typo"
            >
                <Typography
                    variant="subtitle1"
                    height="100%"
                    textAlign="justify"
                    display="inline-block"
                    flexGrow={1}
                    flexShrink={1}
                    lineHeight="3.5vh"
                    whiteSpace="normal"
                    fontSize="2.5vh"
                    fontFamily="BodyText"
                >
                    {props.images[props.hovered].description}
                </Typography>
            
            </Grid>
            </Paper>
        </Grid>
    )
}


function ImagesHolder(props) {
    const theme = useTheme();
    
    return(
        <Grid
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="stretch"
            flexWrap="wrap"
            flexGrow={1}
            sx={{
                backgroundColor: theme.palette.primary.main
            }}
            maxHeight="95%"
            padding={3}
        >
            {
                props.images.map((image, index) => (
                    <ImagePaper
                        src={image.src}
                        title={image.title}
                        image={image}
                        key={index}
                        index={index}
                        hovered={props.hovered}
                        setHovered={props.setHovered}
                    />
                ))
            }
        </Grid>
    )
}


function ImagePaper(props) {

    const [ref, state] = useMeasure();
    const theme = useTheme();

    const [key] = useState(props.index)
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    return (
    <Grid
        xs={6}
        lg={4}    
        display="flex"
        padding={2}
        onMouseEnter={() => props.setHovered(key)}
        onMouseLeave={() => props.setHovered(0)}
        onClick={() => props.setHovered(key)}
        maxHeight={{xs: "33%", lg: "50%"}}
    >
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {
                (props.image.modal === "Liaison") ? 
                    <Liaison open={open} setOpen={setOpen} handleClose={handleClose} {...props.image}/> : 
                    <ServicesModal open={open} setOpen={setOpen} handleClose={handleClose} {...props.image}/>
            }
        </Modal> 
    
        <Paper
            sx={{
                width:"100%",
            }}
            padding={2}
            backgroundColor={theme.palette.primary.main}
            onClick={() => setOpen(true)}
        >
            <Grid
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="stretch"
                alignContent="stretch"
                sx={{
                    height: "100%"
                }}
                padding={2}
            >
                <Box
                    flexGrow={1}
                    ref={ref}
                >
                    <img
                        src={props.src}
                        alt="img"
                        style={{
                            objectFit: "cover",
                            objectPosition: "center center",
                            height: state.height,
                            width: state.width,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        height: "20%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: state.width
                    }}
                    display="flex"
                    flexWrap="wrap"
                    alignItems="flex-start"
                >
                    <Typography
                        variant="body1"
                        whiteSpace="nowrap"
                        textOverflow="ellipsis"
                        fontSize="2vh"
                        maxWidth="100%"
                        overflow="hidden"
                        fontFamily="HeaderFont"
                    >
                        {props.title}
                    </Typography>
                </Box>
            </Grid>
        </Paper>
    </Grid>
    )
}

export default Services;
import { Box, Modal, useTheme } from "@mui/material"
import Slideshow from "./slideshow";
import Services from "./Services";
import About from "./About";

import { forwardRef, useImperativeHandle, useRef } from "react";
import StyledDivider from "./Divider";
import Clients from "./Clients";
import About2 from "./About2";
import Contact from "./Contact";
import Workforce from "./Workforce";
import { useState } from "react";
import Gallery from "./Gallery";

const Body = forwardRef((props, ref) => {
    
    const theme = useTheme();
    
    const servicesRef = useRef(null);
    const aboutRef = useRef(null);
    const clientRef = useRef(null);
    const contactRef = useRef(null);

    useImperativeHandle(ref, () => ({
        services: () => {
            setTimeout(() => {
                servicesRef.current.scrollIntoView({behavior: "smooth"})
            }, 100);
        },
        about: () => {
            setTimeout(() => {
                aboutRef.current.scrollIntoView({behavior: "smooth"})
            }, 100);
        },
        clients: () => {
            setTimeout(() => {
                clientRef.current.scrollIntoView({behavior: "smooth"})
            }, 100);
        },
        gallery: () => {
            setOpen(true);
        },
        contact: () => {
            setTimeout(() => {
                contactRef.current.scrollIntoView({behavior: "smooth"})
            }, 100);
        },
    }))

    
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);

    return (
        <Box
            sx={{
                height: "100%",
                width: "100%",
                backgroundColor: theme.palette.primary.main,
                overflow: "scroll",
                display: "block",
                "&::-webkit-scrollbar": {
                    display: "none"
                }
            }}
        >
            <Slideshow />
            <StyledDivider />
            <About ref={aboutRef}/>
            <StyledDivider />
            <Services ref={servicesRef}/>
            <StyledDivider />
            <Workforce />
            <StyledDivider />
            <Clients ref={clientRef}/>
            <StyledDivider />
            <About2 />
            <StyledDivider />
            <Contact ref={contactRef} />

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="gallery"
                aria-describedby="modal-modal-description"
            >
                <Gallery open={open} setOpen={setOpen} handleClose={handleClose} />
            </Modal>
        </Box>
    )
})

export default Body
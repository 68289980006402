import Typography from '@mui/material/Typography';
import { Divider, Grid, styled, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    width: '100%',
    height: {xs: '92%', lg: '90%'},
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
};


const StyledGrid = styled(Grid)(({ theme }) => ({
    display: 'grid',
    gridAutoColumns: '1fr',
    gridAutoRows: '1fr',
    placeItems: 'stretch stretch',
    placeContent: 'stretch stretch',
    gridAutoFlow: 'row',
    maxWidth: '100%',
    maxHeight: "100%",
    
    [theme.breakpoints.up('xs')]: {
        // Styles for xs breakpoint
        gridAutoColumns: true,
        gridTemplateRows: 'repeat(16, 1fr)',
        maxHeight: '200%',       
    },
  
    [theme.breakpoints.up('lg')]: {
        // Styles for lg breakpoint
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridTemplateRows: 'repeat(9, 1fr)',
        maxHeight: 'auto',
    },
}));


export default function ServicesModal(props) {

    const theme = useTheme();

    return (
        <Grid 
            sx={style}
            overflow='scroll'
        >
        <CloseIcon
                sx={{
                    position: 'absolute',
                    right: 0,
                    transform: {
                        xs: 'translate(-25%, 0%)',
                        lg:'translate(-50%, -50%)'
                    },
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 1,
                    height: '4vh',
                    width: '4vh'
                }}
                onClick={props.handleClose}
            />
        <StyledGrid
            display="flex"
            flexDirection="column"
        >
            
            
            <Grid sx={{
                gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "1 / 7 / 5 / 13"
                    }
                }}
                display="flex"
                justifyContent="center"
                alignItems="center"   
            >
                <img
                    src={props.modalImage1}
                    alt="Liaison"
                    style={{
                        objectFit: "cover",
                        objectPosition: "center center",
                        width: "80%",
                        height: "80%"
                    }}
                />
            </Grid>
            
            <Grid sx={{
                gridArea: {
                        xs: "1 / 1 / auto / 5",
                        lg: "1 / 1 / 5 / 7"
                    }
                }} 
                display="flex"
                direction="column"
                justifyContent="center"
                rowGap={{xs: 3, lg: 1}}
                padding={4}
            >
                <Typography
                    fontSize={{xs: "4vh", lg: "4.5vh"}}
                    display= 'flex'
                    alignItems= 'center'
                    padding={1}
                    color={theme.palette.alter.dark}
                    fontWeight={550}
                    fontFamily="HeaderFont"
                >
                    {props.title}
                </Typography>
                <Divider
                    sx={{
                        height: "1.5vh",
                        width: "60%",
                        backgroundColor: theme.palette.alter.main,
                        border: 0,
                        marginLeft: 1
                    }}
                />
                <Typography
                    display= 'flex'
                    alignItems= 'center'
                    fontSize={{xs: "2.2vh", lg: "2.8vh"}}
                    padding={1}
                    sx={{
                        fontWeight: 400
                    }}
                    align="justify"
                    fontFamily="BodyText"
                >
                    {props.modalDescription}
                </Typography>
            </Grid>
            <Grid sx={{
                    gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "5 / 7 / 10 / 13"
                    }
                }} 
                
                display="flex"
                alignItems='flex-start'
                flexWrap="wrap"
                justifyContent="center"
            >
            {
                props.highlights.map((obj, index) => (
                    <Highlights
                        obj={obj}
                    />
                ))
            }
            </Grid>
            <Grid sx={{
                gridArea: {
                        xs: "auto / 1 / auto / 5",
                        lg: "5 / 1 / 10 / 7"
                    }
                }} 
                display="flex"
                justifyContent="center"
                alignItems="center"
                paddingTop={4}
            >
                <img
                    src={props.modalImage2}
                    alt="Liaison"
                    style={{
                        objectFit: "cover",
                        objectPosition: "center center",
                        width: "80%",
                        height: "80%"
                    }}
                />
            </Grid>

        </StyledGrid>
    </Grid>
  );
}


function Highlights({obj}) {
    
    return (
        <Grid
            xs={6}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="stretch"
            alignContent="stretch"
            padding={2}
        >
            <Grid
                height={{xs: "35%", lg: "40%"}}
                display="flex"
                justifyContent="center"
            >
                <img
                    src={obj.src}
                    alt="Liaison"
                    style={{
                        objectFit: "contain",
                        objectPosition: "center center",
                        width: "50%",
                        height: "100%",
                        padding: "10%"
                    }}
                />
            </Grid>
            <Grid height="auto" maxHeight="20%">
                <Typography
                    variant="h5"
                    fontSize={{xs: "2.2vh", lg:"2.8vh"}}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    textAlign="center"
                    padding={0}
                    height="100%"
                    wordWrap="break-word"
                    fontWeight={600}
                    fontFamily="HeaderFont"
                >
                    {obj.title}
                </Typography>
            </Grid>
            <Grid
                maxHeight={{xs: "45%", lg: "40%"}}
            >
                <Typography
                    variant="subtitle1"
                    fontSize={{xs: "2vh", lg: "2.5vh"}}
                    maxHeight="100%"
                    wordWrap="break-word"
                    lineHeight={{xs: "2.2vh", lg: "2.5vh"}}
                    padding={1}
                    align="justify"
                    fontFamily="BodyText"
                >
                    {obj.description}
                </Typography>
            </Grid>
        </Grid>
    )
}

import React from "react"
import Home from "./components/Home"
import { ThemeProvider, createTheme, useMediaQuery } from "@mui/material"

export default function App() {
    const screenQuery = useMediaQuery('(min-width: 576px) and (max-width: 991.98px)');

    const theme = createTheme({
        palette: {
            primary: {
                main: '#FFFFFF'
            },
            secondary: {
                main: '#FEF4F4'
            },
            tertiary: {
                main: '#44475D'
            },
            footer: {
                main: '#151414'
            },
            alter: {
                main: '#511A37',
                light: '#A0325E'
            },
            onTertiary: {
                main: "#e0e0e0"
            }
        },
        breakpoints: {
            values: {
                xs: screenQuery ? 1 : 0,
                lg: 1024,
            }
        }
    });
    
    return (
        <React.StrictMode>
            <ThemeProvider theme={theme}>
                <Home />
            </ThemeProvider>
        </React.StrictMode>    
    )
}

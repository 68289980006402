import { Divider, Grid, Typography, styled, useMediaQuery, useTheme } from "@mui/material";

export default function About2 () {
    const StyledGrid = styled(Grid)(({ theme }) => ({
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoRows: '1fr',
        gridGap: '1em 1em',
        placeItems: 'stretch stretch',
        placeContent: 'stretch stretch',
        gridAutoFlow: 'row',
        maxWidth: '100%',
        padding: theme.spacing(4),
    
          
        [theme.breakpoints.up('xs')]: {
            // Styles for xs breakpoint
              gridTemplateColumns: 'repeat(4, 1fr)',
              gridAutoRows: true
        },
      
        [theme.breakpoints.up('lg')]: {
            // Styles for lg breakpoint
            gridTemplateColumns: 'repeat(8, 1fr)',
            gridTemplateRows: 'repeat(12, 1fr)'
        },
    }));

    const theme = useTheme();

    const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

    return (
        <StyledGrid
                sx={{
                    maxHeight: {xs: "auto", lg: "auto"},
                    width: "100%",
                }}
        >

            <Grid sx={{
                    gridArea: {
                        xs: "1 / 1 / 4 / 5",
                        lg: "1 / 4 / 6 / 9",
                    }
                }}
                className="environment"
                display="flex"
                direction="column"
                flexWrap="wrap"
                alignContent="center"
                justifyContent="center"
                rowGap={{xs: 4, lg: 1}}
                padding={2}
            >
                <Typography
                    fontSize={{xs: "4vh", lg: "4.5vh"}}
                    display= 'flex'
                    alignItems= 'center'
                    padding={1}
                    color={theme.palette.alter.dark}
                    fontWeight={550}
                    fontFamily="HeaderFont"
                >
                    Environment Friendly Solutions
                </Typography>
                <Divider
                    sx={{
                        height: "1.5vh",
                        width: "60%",
                        backgroundColor: theme.palette.alter.main,
                        border: 0,
                        marginLeft: 1
                    }}
                />
                <Typography
                    display= 'flex'
                    alignItems= 'center'
                    fontSize={{xs: "2.2vh", lg: "2.5vh"}}
                    padding={1}
                    paddingRight={{xs: 1, lg: 15}}
                    sx={{
                        fontWeight: 400
                    }}
                    align="justify"
                    fontFamily="BodyText"
                >
                    Acre 2 Hectares' team is committed to creating innovative solutions in the development of future infrastructure. We strive to provide each client with the best engineering solutions available in an economically feasible and environmentally sound manner. We are dedicated to providing improved work performance, customer satisfaction, productivity and economic benefits.
                </Typography>
            </Grid>
            
            <Grid
                item
                sx={{
                    gridArea: {
                        xs: "auto / 1 / span 2 / 5",
                        //lg: "1 / 1 / 6 / 2",
                        lg: "1 / 1 / 6 / 4",
                    }
                }}
                className="sustaingif"
                display="flex"
                justifyContent="center"
                alignItems='center'
            >
                    <img
                        src={require('../assets/images/sustainable-development.gif')}
                        alt="About"
                        style={{
                            objectFit: "contain",
                            objectPosition: "center center",
                            width: "100%",
                            height: "35vh"
                        }}
                    />
            </Grid>

            <Grid sx={{
                    gridArea: {
                        xs: "auto / 1 / span 4 / 5",
                        lg: "auto / 1 / span 8 / 9",
                    }
                }}
                className="conception"
                display="flex"
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                rowGap={{xs: 4, lg: 1}}
                padding={2}
            >
                <Typography
                    fontSize={{xs: "4vh", lg: "5vh"}}
                    display= 'flex'
                    alignItems= 'center'
                    padding={1}
                    color={theme.palette.alter.dark}
                    fontWeight={550}
                    fontFamily="HeaderFont"
                >
                    From Conception to completion
                </Typography>
                <Divider
                    sx={{
                        height: "1.5vh",
                        width: {xs: "60%", lg: "40%"},
                        backgroundColor: theme.palette.alter.main,
                        border: 0,
                        marginLeft: 1,
                        marginBottom: 4,
                        alignSelf: {xs: "flex-start", lg: "center"}
                    }}
                />
                <img
                    src={require('../assets/images/Conception to Completion.jpg')}
                    alt="About"
                    style={{
                        objectFit: "contain",
                        objectPosition: "center center",
                        width: "100%",
                        height: isLargeScreen ? "60vh" : "40vh", 
                    }}
                />
            </Grid>
        </StyledGrid>
    )
}
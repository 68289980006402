const images = [
    {
        title: "Liaison Service",
        src: require("../assets/images/approval.jpg"),
        description: "We hold expertise in providing liaison services for obtaining necessary Statutory Approval from various Government departments for City Gas Distribution (CGD) and other Industrial approvals",
        modalDescription: "We provide comprehensive services, streamlining the process of obtaining essential documents for legal opinions and land suitability assessments. Our expertise extends to securing approvals and certificates from various departments, including Pollution Control, Factory Safety, Fire and Emergency, NHAI, District Magistrate, Police, Electricity, and Labor, ensuring a smooth and efficient experience for our clients.",
        modalImage1: require('../assets/images/liason1.png'),
        modal: "Liaison",
        highlights : [
            //logo
            {
              src: require("../assets/images/icons/liaisons/land.png"),
              title: "Land Acquisition",
              description: "Obtaining all mandatory documents from various departments. Identification of zonal regulations, obtaining land bifurcations, plan approvals, Commencement certificates and Occupancy certificates from DTCP/KIADB/ CMC/UDA."
            },  
            {
                src: require("../assets/images/donor-consent-form.png"),
                title: "Obtaining CFE and CFO",
                description: "We provide service to obtain CFE/CTE (Consent to Operate), CFO/CTO (Consent to Operate) and HWM (Hazardous Waste Management) from the State Pollution Control Board for all categories of Industries."
            },
            //logo
            {
                src: require("../assets/images/icons/liaisons/factory_plan.png"),
                title: "DISH",
                description: "Obtaining Factory Plan approval, On-Site Emergency Plan (OSEP) and Factory License from Directorate of Industrial Safety and Health department (DISH)."
            },
            //logo
            {
                src: require("../assets/images/icons/liaisons/fire_department.png"),
                title: "Fire and Emergency Department",
                description: "Obtaining NOC and clearance certificates from State Fire and Emergency department."
            },
            //logo
            {
                src: require("../assets/images/icons/liaisons/highway.png"),
                title: "NHAI",
                description: "NOC and Plan sanction from National Highway Authority of India (NHAI)."
            },
            {
                src: require("../assets/images/certificate.png"),
                title: "DM NOC/COP NOC",
                description: "Obtaining NOC from Office of District Magistrate and Commissioner of Police."
            },
            {
                src: require("../assets/images/electric-fire.png"),
                title: "Electricity Department",
                description: "Permissions from Electricity Department, obtaining temporary power, CEIG of BESCOM, MESCOM, GESCOM and TANGEDCO approval for load sanction, Approval for GG/DG set."
            },
            {
              src: require("../assets/images/worker.png"),
              title: "Labour Department",
              description: "We provide services to obtain labour licenses from The Contract Labour Regulation and Abolition (CLRA) and BOCW"
            },
        ]
    },
    
    {
        title: "Topographic Surveying & Mapping",
        src: require("../assets/images/STRIP SURVEY PLAN.jpg"),
        description: "Acre 2 Hectares provides complete 'Field-to-Finish' mapping services offering clients accurate and detailed maps of land topology and features. Field information is collected electronically using feature codes, which computers recognize.",
        modalDescription: "Our Topographic Surveying & Mapping services play a crucial role in land development projects, providing comprehensive and detailed information about the terrain and features of a property. With over two decades of experience, we have honed our expertise to offer innovative solutions in the development of future infrastructure. Our commitment to delivering the best engineering solutions in an economically feasible and environmentally sound manner sets us apart.",
        modalImage1: require('../assets/images/estate_survey.jpg'),
        modalImage2: require("../assets/images/STRIP SURVEY PLAN.jpg"),
        highlights : [
            {
              "title": "Comprehensive Surface Feature Locating",
              "description": "Benefit from our meticulous 3D mapping of natural and man-made features, providing precise details on sizes, heights, and elevation changes.",
              "src": require("../assets/images/3d-model.png")
            },
            {
              "title": "Support for Land Development Work",
              "description": "Rely on our expertise in various surveying and mapping activities for seamless civil engineering projects.",
              "src": require("../assets/images/surveying.png")
            },
            {
              "title": "Private and Public Sector Projects",
              "description": "Trust our proven track record in executing major projects for diverse clients.",
              "src": require("../assets/images/architect.png")
            }
          ]
          
    },
    {
        title: "Marking/Stakeout Survey ",
        src: require("../assets/images/marking_image2.jpg"),
        description: "We provide survey to establish location of structures, objects and/or property corners based on proposed plans.",
        modalDescription: "Acre 2 Hectares' \"Marking/Stakeout Survey\" service is the linchpin for design and actual construction. It involves physically marking key points like property corners, foundations, columns, utilities, and pavement layouts according to design blueprints. This meticulous process ensures pinpoint accuracy in translating plans into reality, guaranteeing flawless execution. By leveraging this service, Acre 2 Hectares not only facilitates smooth construction but also guarantees stability, functionality, and visual coherence in the end product. The Marking/Stakeout Survey epitomizes Acre 2 Hectares' commitment to translating ideas into precise, perfectly aligned land development projects.",
        modalImage1: require('../assets/images/stakeout-marking.jpg'),
        modalImage2: require("../assets/images/marking_image2.jpg"),
        highlights : [
            {
              "title": "Property Corner Staking",
              "description": "Property corner staking involves physically marking the corners of a property based on proposed plans. This service ensures that the boundaries of the property are accurately established, aiding in the proper placement of structures and developments.",
              "src": require("../assets/images/icons/marketing/mapping.png")
            },
            {
              "title": "Foundation Stakeout",
              "description": "Foundation stakeout entails marking the precise locations for building foundations according to architectural or engineering plans. This service ensures that the foundation is correctly positioned on the site, which is crucial for the stability and alignment of the entire structure.",
              "src": require("../assets/images/icons/marketing/building.png")
            },
            {
              "title": "Column and Utility Layout",
              "description": "Column and utility layout involves marking the positions of columns and utilities such as electrical, plumbing, and drainage systems based on construction plans. Accurate placement of columns and utilities helps ensure that the building's structural elements and services are properly aligned.",
              "src": require("../assets/images/icons/marketing/layout.png")
            },
            {
              "title": "Pavement and Curb Marking",
              "description": "Pavement and curb marking service focuses on marking the layout of pavements, curbs, and other ground-level structures according to design specifications. This process is essential for achieving proper traffic flow, drainage, and aesthetics in the final development.",
              "src": require("../assets/images/icons/marketing/street.png")
            }
          ]
          
    },

    {
        title: "As Built-Plans / Drawings",
        src: require("../assets/images/AS BUILT SURVEY COFFEE ESTATE.jpg"),
        description: "Our CADD engineers are capable to create 3-D digital surfaces, horizontal and vertical alignments, cut profiles, generate cross-sections, calculate earthwork volumes, generate reports and create plan and profile sheets.",
        modalDescription: "Acre 2 Hectares' \"As-Built Plans/Drawings\" service provides a comprehensive perspective on completed projects in relation to their original designs. These precise documents enable meticulous comparison between envisioned specifications and actual outcomes, offering a detailed blueprint of both built structures and surrounding land. Demonstrating alignment between design intent and execution, this service enhances clients' understanding of their concepts realized. By accurately documenting final results, Acre 2 Hectares empowers clients to assess execution accuracy and make informed decisions for subsequent phases, exemplifying their commitment to precision and transparency in land development projects.",
        modalImage1: require('../assets/images/asbuilt-image1.jpg'),
        modalImage2: require("../assets/images/AS BUILT SURVEY COFFEE ESTATE.jpg"),
        highlights : [
            {
              "title": "Comparative Assessment",
              "description": "Acre 2 Hectares conducts a thorough comparative assessment between the original design specifications and the final constructed project. This service allows clients to precisely gauge variations and deviations, providing insights into how the project aligns with its initial vision.",
              "src": require("../assets/images/icons/builtplans/compare.png")
            },
            {
              "title": "Detailed Blueprint Generation",
              "description": "The Detailed Blueprint Generation service entails creating meticulous drawings that accurately represent the constructed building and its immediate surroundings. These drawings serve as a visual reference that showcases the physical realization of the project.",
              "src": require("../assets/images/icons/builtplans/blueprint.png")
            },
            {
              "title": "Specification Verification",
              "description": "Through Specification Verification, Acre 2 Hectares meticulously checks and documents that the constructed elements, from building features to infrastructure installations, adhere to the originally planned specifications. This ensures transparency and aids in quality control.",
              "src": require("../assets/images/icons/builtplans/verification.png")
            },
            {
              "title": "Visual Documentation for Future Projects",
              "description": "Acre 2 Hectares offers Visual Documentation services that provide a comprehensive record for future reference. These records include accurate depictions of the built structures, enabling clients to make informed decisions for future phases, renovations, or expansions based on real-world insights.",
              "src": require("../assets/images/icons/builtplans/visual.png")
            }
          ]
          
    },
    {
        title: "DGPS Survey",
        src: require("../assets/images/settingsurvey.jpg"),
        description: "Using Differential Global Positioning System to identify the corrections in the positional readings from the GPS. An enhancement to the Global Positioning System (GPS) The DGPS service improves the accuracy of your GPS position and the quality of the signal from the 15-meter nominal GPS accuracy to about 1–3 cm in case of the best implementations of DGPS.",
        modalDescription: "Acre 2 Hectares offers the highly precise \"DGPS Survey\" service, utilizing the Differential Global Positioning System (DGPS) to enhance the accuracy of positional readings derived from GPS technology. DGPS involves corrective measures to refine GPS positional data, enhancing the quality of signal reception and significantly improving accuracy. This service elevates the standard GPS accuracy from a nominal 15-meter range to an impressive 1-3 cm range, particularly in optimal DGPS implementations.",
        modalImage1: require('../assets/images/dgps-image1.jpg'),
        modalImage2: require("../assets/images/settingsurvey.jpg"),
        highlights : [
            {
              "title": "Positional Correction Enhancement",
              "description": "Acre 2 Hectares specializes in augmenting the accuracy of GPS-based positional data through DGPS technology. This service involves applying corrective measures to GPS signals, refining location accuracy to a remarkable 1-3 cm range, ensuring utmost precision in spatial data collection.",
              "src": require("../assets/images/icons/dgps/brand-positioning.png")
            },
            {
              "title": "Site Mapping and Survey",
              "description": "The DGPS service encompasses comprehensive site mapping and survey capabilities. Acre 2 Hectares employs DGPS to precisely map landscapes, properties, and infrastructure, enabling clients to gather highly accurate spatial information for planning, design, and decision-making.",
              "src": require("../assets/images/icons/dgps/data.png")
            },
            {
              "title": "Construction Layout Accuracy",
              "description": "With the DGPS-based Construction Layout Accuracy service, Acre 2 Hectares ensures pinpoint precision in translating architectural plans to physical reality. By enhancing GPS accuracy, this service guarantees that building foundations, structures, and utilities align impeccably with design specifications.",
              "src": require("../assets/images/icons/dgps/construction.png")
            },
            {
              "title": "Infrastructure Monitoring",
              "description": "Acre 2 Hectares' DGPS-enabled Infrastructure Monitoring service aids in tracking the stability and changes of structures and landscapes over time. By providing precise data on positional shifts, this service supports proactive maintenance and informed decision-making for infrastructure management and safety.",
              "src": require("../assets/images/icons/dgps/infrastructure.png")
            }
          ]
    },
    {
        title: "Geo Technical Investigation",
        src: require("../assets/images/SOIL TEST 3 (1).jpg"),
        description: "We provide Geotechnical investigation where the physical qualities of a site are evaluated in order to determine if the site is suitable and safe for the proposed purpose.",
        modalDescription: "Acre 2 Hectares introduces the essential \"Geo Technical Investigation\" service, which systematically evaluates the physical attributes of a site to determine its suitability and safety for the proposed purpose. This comprehensive process involves assessing a site's subsurface conditions, encompassing soil types, rock formations, groundwater levels, and engineering properties. Geo Technical Investigation is instrumental in understanding a site's characteristics, facilitating informed decision-making for land development projects.",
        modalImage1: require('../assets/images/SOIL TEST 3 (1).jpg'),
        modalImage2: require("../assets/images/SOIL TEST 4 (1).jpg"),
        highlights : [
            {
              "title": "Site Suitability Assessment",
              "description": "Acre 2 Hectares excels in Site Suitability Assessment through Geo Technical Investigation. This service involves thorough evaluations to ascertain whether a site is suitable and safe for the intended use, enabling clients to make well-informed choices based on accurate subsurface insights.",
              "src": require("../assets/images/icons/geotechnical/suitable.png")
            },
            {
              "title": "Subsurface Condition Analysis",
              "description": "The Geo Technical Investigation service includes Subsurface Condition Analysis, providing clients with a detailed understanding of soil compositions, rock formations, and groundwater presence. Acre 2 Hectares' expertise ensures precise identification of site characteristics for effective project planning.",
              "src": require("../assets/images/icons/geotechnical/geological.png")
            },
            {
              "title": "Engineering Properties Evaluation",
              "description": "Through the Engineering Properties Evaluation service, Acre 2 Hectares leverages Geo Technical Investigation to assess key engineering properties of the site, such as load-bearing capacity and soil stability. This vital information informs design and construction strategies, ensuring structural integrity.",
              "src": require("../assets/images/icons/geotechnical/civil-engineering.png")
            },
            {
              "title": "Comprehensive Testing Methodologies",
              "description": "Acre 2 Hectares employs a range of comprehensive testing methodologies in Geo Technical Investigation, including test pits, trenching, boring, and in situ testing. These techniques enable accurate data collection, which is then professionally interpreted to inform site improvement design and construction.",
              "src": require("../assets/images/icons/geotechnical/drill.png")
            }
          ]
          
    },
    
]

export default images;
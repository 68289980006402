import { Divider, Grid, Typography, styled, useTheme } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -45%)',
    width: '100%',
    height: {xs: '92%', lg: '90%'},
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: "90%",
};

const StyledGrid = styled(Grid)(({ theme }) => ({
    width: '100%',
    
    [theme.breakpoints.up('xs')]: {
        display: "flex",
        flexWrap: "wrap",
        flexDirection: "row",
        justifyContent: "center",
        rowGap: theme.spacing(16),

        marginTop: theme.spacing(10)
    },
  
    [theme.breakpoints.up('lg')]: {
        display: 'grid',
        gridAutoColumns: '1fr',
        gridAutoRows: '1fr',
        placeItems: 'stretch stretch',
        placeContent: 'stretch stretch',
        gridAutoFlow: 'row',
        gridTemplateColumns: 'repeat(12, 1fr)',
        gridGap: "5em 2em",
        
        marginTop: theme.spacing(10)
    },
}));



const Gallery = (props) => {
    const theme = useTheme();

    const gallery = [
        require("../assets/images/office/office_entrance.jpeg"),
        require("../assets/images/office/office_portrait_1.jpeg"),
        require("../assets/images/office/office_portrait_2.jpeg"),
        require("../assets/images/office/office_portrait_3.jpeg"),
        require("../assets/images/site/site_2.jpeg"),
        require("../assets/images/site/site_12.jpeg"),
        require("../assets/images/office/office_4.jpeg"),
        require("../assets/images/office/office_6.jpeg"),
        require("../assets/images/office/office_7.jpeg"),
        require("../assets/images/site/site_1.jpeg"),
        require("../assets/images/site/site_3.jpeg"),
        require("../assets/images/site/site_4.jpeg"),
        require("../assets/images/site/site_5.jpeg"),
        require("../assets/images/site/site_6.jpeg"),
        require("../assets/images/site/site_7.jpeg"),
        require("../assets/images/site/site_8.jpeg"),
        require("../assets/images/site/site_11.jpeg"),
        require("../assets/images/site/site_9.jpeg"),
    ]
    return (
        <Grid
            sx={style}
            overflow='scroll'
        >
            <CloseIcon
                sx={{
                    position: 'absolute',
                    right: 0,
                    transform: {
                        xs: 'translate(-25%, 0%)',
                        lg:'translate(-50%, -50%)'
                    },
                    backgroundColor: theme.palette.secondary.main,
                    borderRadius: 1,
                    height: '4vh',
                    width: '4vh'
                }}
                onClick={props.handleClose}
            />

            <Typography
                fontSize={{xs: "4vh", lg: "6.5vh"}}
                display= 'flex'
                alignItems= 'center'
                padding={1}
                color={theme.palette.alter.dark}
                fontWeight={550}
                fontFamily="HeaderFont"
            >
                Gallery
            </Typography>
            <Divider
                sx={{
                    height: "1.5vh",
                    width: "30%",
                    backgroundColor: theme.palette.alter.main,
                    border: 0,
                    marginLeft: 1
                }}
            />

            <StyledGrid>
                {
                    gallery.map((src, index) => (
                        <Grid
                            key={index}

                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                            sx={{
                                gridArea: {
                                    xs: "auto / span " + ((index > 20) ? 12 : 12) + " / auto / auto",
                                    lg: "auto / span " + ((index > 20) ? 6 : 4) + " / auto / auto",
                                },
                                height: "auto"
                            }}
                        >
                        
                            <img
                                src={src}
                                alt="gallery"
                                style={{
                                    objectFit: "contain",
                                    maxWidth: "100%",
                                    height: (index > 5) ? "40vh" : "60vh",
                                }}
                            />
                        </Grid>
                    ))
                }
            </StyledGrid>
        </Grid>
    )
}

export default Gallery
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";

import '../stylesheets/fonts.css';
import { useMediaQuery, useTheme } from "@mui/material";

const NavBar = React.forwardRef((props, ref) => {
  const [anchorElNav, setAnchorElNav] = React.useState(
    null
  );

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  
  const pages = ["About", "Services", "Clients", "Gallery", "Contact"];

  const theme = useTheme();
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <AppBar
      position="static"
      color="tertiary"
      ref={ref}
      alignSelf="stretch"
      display="flex"
    >
        <Toolbar
          disableGutters
          display="flex"
          sx={{
            alignItems:"center",
            justifyContent:"center",
            alignSelf:"stretch",
            height: "100%",
          }}
        >
          <img 
            src={require("../assets/images/hectre.png")}
            alt="logo"
            style={{
              maxHeight: isLargeScreen ? "65%" : "55%",
              //padding: isLargeScreen ? "1% 0% 1.2% 1.5%" : "2.8% 1.5% 3.5% 3.5%",
              margin: isLargeScreen ? "1% 0.5% 1% 1%" : "1.2% 1% 1.2% 1.2%"
            }}
          />
          <Typography
            noWrap
            sx={{
              fontFamily: "HeaderFont",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              letterSpacing: ".3rem",
              color: theme.palette.onTertiary.main,
              padding: isLargeScreen ? "4 2 10 3" : "10",
              position: "relative",
            }}
            fontSize={{xs: "3.5vh", lg: "6.5vh"}}
          >
            <span>Acre 2 Hectares</span>
            <span style={{
                letterSpacing: ".2rem",
                position: "absolute",
                fontSize: isLargeScreen ? "2.5vh" : "1.5vh", 
                marginLeft: "1rem",
                bottom: isLargeScreen ? 2 : -2,
                right: isLargeScreen ? 4 : 12,
                fontFamily: "LogoSubFont",
              }}>
              Since 2002
            </span>
          </Typography>

          {/* Menu large screen */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", lg: "flex" },
              justifyContent: "flex-end",
              alignItems: "stretch",
              alignSelf: "center"
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {props.scrollToSection(page); handleCloseNavMenu()}}
                disableRipple
                sx={{
                  m: 2,
                  color: theme.palette.onTertiary.main,
                  display: "block",
                  fontFamily: "HeaderFont",
                  fontWeight: 600,
                  fontSize: "2.2vh",
                  letterSpacing: 3,
                  height: "100%",
                  '&:hover': {
                    backgroundColor: theme.palette.secondary.main,
                    margin: theme.palette.action.focus,
                    color: theme.palette.text.primary
                  },
                  '&:focus': {
                    outline: 'none', // Add an outline on focus
                  },
                  '&:active': {
                    transform: 'scale(0.95)',
                  },
                }}
              >
                {page}
              </Button>
            ))}
          </Box>

          {/* Menu small screen */}
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", lg: "none" },
              justifyContent: "flex-end",
              alignItems: "center",
              height: {sm: "8vh"}
            }}
          >
            <IconButton
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="onTertiary"
              sx={{
                  '&:hover': {
                    backgroundColor: theme.palette.onTertiary.main,
                    color: theme.palette.text.primary
                  },
                  '&:focus': {
                    outline: 'none',  
                  },
                  '&:active': {
                    transform: 'scale(0.95)',
                  },
              }}
            >
              <MenuIcon
                sx={{
                  width: {
                    xs: '5vh', lg: '2em'
                  },
                  height: {
                    xs: '5vh', lg: '2em'
                  }
                }}  
              />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right"
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right"
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", lg: "none" },
                "& .MuiMenu-paper": {
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.text.primary,
                },
              }}
              PaperProps={{
                sx: {
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch"
                }
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {props.scrollToSection(page); handleCloseNavMenu()}}
                  sx={{
                      '&:hover': {
                        backgroundColor: theme.palette.tertiary.main,
                      },
                      '&:focus': {
                        outline: 'none',
                      },
                      '&:active': {
                        transform: 'scale(0.95)',
                      },
                  }}
                >
                  <Typography
                    textAlign="center"
                    fontFamily="HeaderFont"
                    fontWeight={400}
                    fontSize="2.5vh"
                    padding={3}
                  >
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
    </AppBar>
  );
})

export default NavBar;
import { Grid, Typography, useTheme } from "@mui/material"
import { forwardRef } from "react";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
import StyledDivider from "./Divider";

const Contact = forwardRef((props, ref) => {
    const theme = useTheme();

    return(
        <Grid
            ref={ref}
            maxHeight={{xs: "auto", lg: "auto"}}
            sx={{
                backgroundColor: theme.palette.footer.main,
                paddingBottom: {xs: 20, lg: 2}
            }}
        >
        
            <Typography
                fontWeight={600}
                fontSize="4.5vh"
                padding="1% 0 2% 0"
                variant="h6"
                color={theme.palette.primary.main}
                textAlign='center'
            >
                Contact Us
            </Typography>

        <Grid
            display="flex"
            flexDirection={{xs: "column", lg: "row"}}
            justifyContent="space-around"
            alignItems="stretch"
            maxHeight="90%"
            rowGap={5}
        >
        
            <StyledDivider sx={{width:"100%", height: 4,display: {xs: "block", lg: "none"}, alignSelf: "center"}}/>
            <Subs
                icon={<CallIcon
                    sx={{
                        color: theme.palette.alter.light,
                        height: {xs: "3vh" ,lg:"3vh"},
                        width: {xs: "3vh" ,lg:"3vh"},
                        alignSelf: "center"
                    }}
                />}
                header="Phone"
            >
                <Typography
                    fontWeight={600}
                    fontSize={{xs: "1.8vh", lg: "2.8vh"}}
                    padding={2}
                    variant="body2"
                    color={theme.palette.primary.main}
                >
                    9741913655, <br />9900123305, <br />9845494881
                </Typography>
            </Subs>
            
            <StyledDivider sx={{width:"90%", display: {xs: "block", lg: "none"}, alignSelf: "center"}}/>
            <Subs
                icon={<LocationCityIcon sx={{
                        color: theme.palette.alter.light,
                        height: {xs: "3vh" ,lg:"3vh"},
                        width: {xs: "3vh" ,lg:"3vh"},
                        alignSelf: "center"
                    }}
                />}
                header="Location"
            >
                <a
                    className="contact-location-link"
                    href="https://www.google.com/maps/place/12%C2%B053'55.6%22N+77%C2%B039'29.8%22E/@12.898771,77.65829,17z/data=!3m1!4b1!4m4!3m3!8m2!3d12.898771!4d77.65829"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: "1.8vh", lg: "2.8vh"}}
                        padding={2}
                        variant="body2"
                        color={theme.palette.primary.main}
                    >
                        #36, 4th Floor, Megha Arcade, Reliable Residency, Next to IOC Fuel
                        Station, Haralur Main Road, Bangalore–560 102
                    </Typography>
                </a>
            </Subs>

            <StyledDivider sx={{width:"90%", display: {xs: "block", lg: "none"}, alignSelf: "center"}}/>
            <Subs
                icon={<EmailIcon
                    sx={{
                        color: theme.palette.alter.light,
                        height: {xs: "3vh" ,lg:"3vh"},
                        width: {xs: "3vh" ,lg:"3vh"},
                        alignSelf: "center"
                    }}
                />}
                header="Mail To"
            >
                <a href="mailto:sureshpdevaiya@acre2hectares.com" className="contact-location-link" rel="noopener noreferrer" target="_blank">
      
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: "1.8vh", lg: "2.8vh"}}
                        paddingTop={2}
                        paddingLeft={2}
                        paddingRight={2}
                        variant="body2"
                        color={theme.palette.primary.main}
                    >
                        sureshpdevaiya@acre2hectares.com
                    </Typography>
                </a>
                <a href="mailto:acre2hectares@gmail.com" className="contact-location-link" rel="noopener noreferrer" target="_blank">
      
                    <Typography
                        fontWeight={600}
                        fontSize={{xs: "1.8vh", lg: "2.8vh"}}
                        paddingLeft={2}
                        paddingRight={2}
                        variant="body2"
                        color={theme.palette.primary.main}
                    >
                        acre2hectares@gmail.com
                    </Typography>
                </a>
            </Subs>

            <StyledDivider sx={{width:"90%", display: {xs: "block", lg: "none"}, alignSelf: "center"}}/>
        </Grid>
        </Grid>
    )
})

function Subs (props) {
    const theme = useTheme();
    return(
        <Grid
            flexGrow={1}
            maxWidth={{xs: "100%",lg: "34%"}}
            display="flex"
            flexDirection={{xs: "row", lg: "column"}}
            justifyContent={{xs:"space-between", lg: "flex-start"}}
            alignItems="center"
        >
            <Grid
                display="flex"
                flexDirection="row"
                alignItems={{xs:"center", lg: "flex-end"}}
                paddingLeft={{xs: 2, lg: 0}}
            >
                {props.icon}
                <Typography
                    textAlign="center"
                    fontWeight={600}
                    fontSize={{xs: "2vh", lg: "3vh"}}
                    paddingLeft={{xs: 4, lg: 1}}
                    color={theme.palette.alter.light}
                >
                    {props.header}
                </Typography>
            </Grid>
            <Grid
                height="80%"
                width={{xs: "80%", lg: "100%"}}
                textAlign="center"
                paddingLeft={{xs: 4, lg: 0}}
                paddingRight={{xs: 4, lg: 0}}
            >
                {props.children}
            </Grid>
        </Grid>
    )
}

export default Contact;
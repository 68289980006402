import { Grid } from "@mui/material";
import NavBar from "./Navbar";
import Body from "./Body";
import { useRef } from "react";

export default function Home() {

    const bodyRef = useRef();
    const scrollToSection = (page) => {
        if(page === "Services")
            bodyRef.current.services();
        else if(page === "About")
            bodyRef.current.about();
        else if(page === "Clients")
            bodyRef.current.clients();
        else if(page === "Contact")
            bodyRef.current.contact();
        else if(page === "Gallery")
            bodyRef.current.gallery();
    };
    

    return (
        <Grid
            container
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "stretch",
                height: "100%",
                flexWrap: "nowrap"
            }}
        >
            <Grid
                item
                display="flex"
                sx={{
                    height: { xs: "8%", lg: "12%"}
                }}
            >
                <NavBar
                    scrollToSection={scrollToSection}
                />
            </Grid>
            <Grid
                item
                sx={{
                    overflow: "scroll",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    height: { xs: "92%", lg: "90%"}
                }}
                flexGrow={1}
                height="90%"

            >
                <Body
                    ref={bodyRef}
                />
            </Grid>
        </Grid>
    )
}
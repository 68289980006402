import { Divider, styled } from "@mui/material"

const StyledDivider = styled(Divider)(({ theme }) => ({
    height: "0.1em",
    backgroundColor: theme.palette.tertiary.main,
    border: 0,
    alignSelf: "flex-end",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
}))

export default StyledDivider;